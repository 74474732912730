import React from "react";

export const About = () => (
    <section className="feature" id="about">
        <div className=" col-lg-12 col-12 text-center mb-5">
            <h2 className="mb-1 text-white">ABOUT US</h2>
            <h6>Our vision and mission</h6>
        </div>
        <div className="container">
            <div className="row feature-column">
                <div className="col-lg-6 col-md-12 col-12 image-container">
                    <img src="images/electricians.jpg" alt="About Us Image" className="about-image"/>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                    <div className="about-working-hours">
                        <p className="mb-4 text-white">
                            Our company was founded back in 2020 and it became rapidly a medium enterprise in the electrical industry.
                            Our main focus is providing high quality services for factories to be powered up.
                            <br/><br/>
                            We believe that the key in today’s manufacturing is to be up-to-date and be ready to implement the newest technologies.
                            That’s why we are keeping up with the modern production automation.
                            <br/><br/>
                            If your company needs someone who can manage all of your needs within the electrical industry, look no further.
                            <br/><br/>
                            Our organization from project management, engineering and production to implementation can take any demands.
                            We are able to provide highly skilled and experienced electricians and project managers as contractors.
                        </p>
                        <a href="#" className="btn custom-btn bg-color mt-3" data-toggle="modal"
                           data-target="#membershipForm">Contact us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
);