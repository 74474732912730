import React from "react";

export const Services = () => (
    <section className="feature-section" id="services">
        <div className="container">
            <div className="row text-center mb-5">
                <div className=" col-lg-12 col-12 text-center mb-5">
                    <h2 className="mb-1 text-white">OUR SERVICES</h2>
                    <h6>More on what we offer</h6>
                </div>
            </div>
            <div className="row d-flex justify-content-around flex-wrap">
                <div className="col-md-4 mb-4">
                    <div className="service-card">
                        <div className="card-header">
                            <h3>Industrial Automation</h3>
                        </div>
                        <div>
                            <img
                                src="images/class/industrial-automation.jpeg"
                                className="w-100 p-2"
                                alt="..."
                            />
                        </div>
                        <div className="card-body">
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseAutomation"
                                aria-expanded="false"
                                aria-controls="collapseAutomation"
                            >
                                Learn More
                            </button>
                            <div className="collapse mt-3" id="collapseAutomation">
                                <p style={{textAlign: "left"}}>
                                    Industrial automation installation – Design of automation for production lines.
                                    Undertaking project management, site management as contractor from first screw to
                                    testing phase. We specialize in the implementation of production lines, especially
                                    from the electrical side such as wiring, cable tray installation. From planning 3D
                                    designs to making them a reality in a factory, you can trust us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div
                        className="service-card"
                    >
                        <div className="card-header">
                            <h3>Building Electricity</h3>
                        </div>
                        <div>
                            <img
                                src="images/class/building.jpeg"
                                className="w-100 p-2"
                                alt="..."
                            />
                        </div>
                        <div className="card-body">
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseElectricity"
                                aria-expanded="false"
                                aria-controls="collapseElectricity"
                            >
                                Learn More
                            </button>
                            <div className="collapse mt-3" id="collapseElectricity">
                                <p style={{textAlign: "left"}}>
                                    Building electricity – Project management, construction management, complete low and
                                    high voltage construction. Our experience in building electricity spans from
                                    apartments to complete industrial factories. Depending on the needs, we can
                                    undertake as contractors the entire low and high voltage setup.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div
                        className="service-card"
                    >
                        <div className="card-header">
                            <h3>Renewable Energy</h3>
                        </div>
                        <div>
                            <img
                                src="images/class/solar-pane.webp"
                                className="w-100 p-2"
                                alt="..."
                            />
                        </div>
                        <div className="card-body">
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseRenewable"
                                aria-expanded="false"
                                aria-controls="collapseRenewable"
                            >
                                Learn More
                            </button>
                            <div className="collapse mt-3" id="collapseRenewable">
                                <p style={{textAlign: "left"}}>
                                    Our company ensures precise and professional execution of solar systems, from
                                    customer care and documentation to final installation. Our skilled installers and
                                    project managers handle the process efficiently and answer any queries, solving
                                    problems in a customer-oriented way. Our range goes from civil works to industrial
                                    level solar systems.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)