import React, {useEffect, useRef, useState} from "react";
import {BrowserRouter as Router, Link, Route, Routes, useNavigate} from "react-router-dom";
import CookieConsent, {Cookies, getCookieConsentValue} from "react-cookie-consent";
import {initGA} from "./ga-utils";
import {Home} from "./home/home";
import {About} from "./about/about";
import {Features} from "./features/features";
import {Services} from "./services/services";
import {References} from "./references/references";
import {Contact} from "./contact/contact";
import Pdf from "./pdf/terms.pdf";

import "./App.css";

function App() {
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const featuresRef = useRef(null);
    const servicesRef = useRef(null);
    const referencesRef = useRef(null);
    const contactRef = useRef(null);

    const handleAcceptCookie = () => {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
            initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        }
    };

    const handleDeclineCookie = () => {
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }

        // Add Google Tag Manager script
        const script = document.createElement("script");
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W2MXB3QB');`;
        document.head.appendChild(script);

    }, []);

    const handleScroll = (ref) => {
        ref.current?.scrollIntoView({
            behavior: 'smooth'
        });
    };

    const Navbar = () => {
        const navigate = useNavigate();
        return (
            <nav className="navbar navbar-expand-lg fixed-top">
                <div className="container nav-flex">
                    <Link className="nav-link" to="/" style={{cursor: 'pointer'}}>
                        <img src="images/logo02_zold.png" className="navbar-brand" alt="Logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-lg-auto">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#about"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleScroll(aboutRef);
                                        navigate("/#about");
                                    }}
                                    style={{cursor: 'pointer'}}
                                >
                                    About
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#services"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleScroll(servicesRef);
                                        navigate("/#services");
                                    }}
                                    style={{cursor: 'pointer'}}
                                >
                                    Services
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#references"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleScroll(referencesRef);
                                        navigate("/#references");
                                    }}
                                    style={{cursor: 'pointer'}}
                                >
                                    References
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#contact"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleScroll(contactRef);
                                        navigate("/#contact");
                                    }}
                                    style={{cursor: 'pointer'}}
                                >
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    };

    const Footer = () => (
        <footer className="site-footer">
            <div className="container footer">
                <div className="row">
                    <div>
                        <p className="copyright-text">
                            Copyright &copy; 2023 Building Control co.
                            <br/>
                            Design: <a href={'https://www.linkedin.com/in/izs%C3%A1k-semsei/'}>Semsei Izsák</a>
                        </p>
                    </div>
                </div>

                <img
                    src="images/kicsilogo.png"
                    alt="Building Cntrl"
                    width="100"
                    height="100"
                />
            </div>
        </footer>
    );

    const Modal = () => (
        <div className="modal fade" id="membershipForm" tabIndex="-1" role="dialog"
             aria-labelledby="membershipFormLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="membershipFormLabel">
                            Get in contact
                        </h2>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form
                            className="membership-form webform"
                            role="form"
                            action="https://api.web3forms.com/submit"
                            method="POST"
                        >
                            <input
                                type="hidden"
                                name="access_key"
                                value="251e0cb8-3ec2-4418-b25e-9d70ffbbf29a"
                            />

                            <input
                                type="text"
                                className="form-control"
                                name="cf-name"
                                placeholder="John Doe"
                                required
                            />

                            <input
                                type="email"
                                className="form-control"
                                name="cf-email"
                                placeholder="Johndoe@gmail.com"
                                required
                            />

                            <input
                                type="tel"
                                className="form-control"
                                name="cf-phone"
                                placeholder="+36301231234"
                                pattern="\+[0-9]{2}[0-9]{1,15}"
                            />

                            <textarea
                                className="form-control"
                                rows="3"
                                name="cf-message"
                                placeholder="Additional Message"
                            ></textarea>

                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="consentChecked"
                                    name="consentChecked"
                                    //checked={formData.consentChecked}
                                    //onChange={handleChange}
                                    style={{width: "16px", height: "16px"}}
                                />
                                <label className="form-check-label" htmlFor="consentChecked">
                                    I agree to the <a href={Pdf} target="_blank">terms</a> and conditions.
                                </label>
                                {/*{errors.consentChecked &&
                                    <p style={{color: "red"}} className="error">{errors.consentChecked}</p>}*/}
                            </div>

                            <button
                                type="submit"
                                className="form-control"
                                id="submit-button"
                                name="submit"
                            >
                                Submit Button
                            </button>
                        </form>
                    </div>

                    <div className="modal-footer"></div>
                </div>
            </div>
        </div>
    );

    return (
        <Router>
            <div>
                <Navbar/>

                <Routes>
                    <Route path="/" element={
                        <>
                            <div ref={homeRef}><Home/></div>
                            <div ref={aboutRef}><About/></div>
                            <div ref={featuresRef}><Features/></div>
                            <div ref={servicesRef}><Services/></div>
                            <div ref={referencesRef}><References/></div>
                            <div ref={contactRef}><Contact/></div>
                        </>
                    }/>

                    <Route path="/about" element={<About/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/references" element={<References/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>

                <Footer/>
                <Modal/>

                <CookieConsent
                    enableDeclineButton
                    onAccept={handleAcceptCookie}
                    onDecline={handleDeclineCookie}
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
            </div>
        </Router>
    );
}

export default App;
