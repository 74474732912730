import {Link} from "react-scroll";
import React from "react";

export const Home = () => (
    <section
        className="hero d-flex flex-column justify-content-center align-items-center"
        id="home"
    >
        <div className="bg-overlay"></div>
        <div className="container" style={{zIndex: "30"}}>
            <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto col-12">
                    <div className="hero-text mt-5 text-center">
                        <h2 className="text-white main-page-content">
                            Wide range of services within the electrical industry,
                            provided by reliable professionals.
                        </h2>
                        <Link
                            to="references"
                            className="btn custom-btn mt-3"
                            smooth={true}
                            duration={500}
                        >
                            Learn more
                        </Link>
                        <a
                            className="btn custom-btn bg-color mt-3"
                            data-toggle="modal"
                            data-target="#membershipForm"
                        >
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
);