import Slider from "react-slick";
import React from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@radix-ui/react-icons";

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <ChevronRightIcon
            className={className}
            style={{
                ...style,
                color: "black",
                width: "40px",
                height: "40px",
                paddingLeft: "20px",
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <ChevronLeftIcon
            className={className}
            style={{
                ...style,
                color: "black",
                width: "40px",
                height: "40px",
                paddingRight: "20px",
            }}
            onClick={onClick}
        />
    );
}

const settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    infinite: true,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export const References = () => (
    <section className="projects-section" id="references">
        <div className="row">
            <div className="projects-title">
                <div className=" col-lg-12 col-12 text-center mb-5">
                    <h2>WORKS FROM THE LAST YEARS</h2>
                    <h6>Our biggest projects yet</h6>
                </div>
            </div>
            <div className="slider-container">
                <Slider {...settings}>
                    <div className="project-card">
                        <img
                            src="images/class/cardio-class.jpg"
                            className="d-block w-100"
                            alt="Michelin project image"
                        />
                        <div className="project-info">
                            <h3 className="mb-1">Michelin</h3>
                            <span>
                    <strong>(Romania, Zalau)</strong>
                  </span>
                            <span className="project-year">2022</span>
                            <p className="mt-3">
                                Installation of 4 production lines and power supply for
                                Zeppelin France Automation. Planning, installation of trays,
                                cables, and supporting the I/O test.
                            </p>
                        </div>
                    </div>

                    <div className="project-card">
                        <img
                            src="images/class/crossfit-class.jpg"
                            className="d-block w-100"
                            alt="Barry Callebaut project image"
                        />
                        <div className="project-info">
                            <h3 className="mb-1">Barry Callebaut</h3>
                            <span>
                    <strong>(Sweden, Kågeröd)</strong>
                  </span>
                            <span className="project-year">2022</span>
                            <p className="mt-3">
                                Installation of 4 production lines and power supply,
                                including planning and deployment of trays, cables, and
                                supporting the I/O test.
                            </p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img
                            src="images/class/yoga-class.jpg"
                            className="d-block w-100"
                            alt="FirstFarms Kft project image"
                        />
                        <div className="project-info">
                            <h4 className="mb-1">
                                <b>FirstFarms Kft</b>
                            </h4>
                            <span>
                    <strong>(Hungary, Telekgerendás)</strong>
                  </span>
                            <span className="project-year">2023</span>
                            <p className="mt-3">
                                Technological cabling for fattening automation, encompassing
                                sensor connection and integration with other automation
                                controls.
                            </p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img
                            src="images/class/Magyar3.png"
                            className="d-block w-100"
                            alt="Nestlé Purina project image"
                        />
                        <div className="project-info">
                            <h3 className="mb-1">Nestlé Purina</h3>
                            <span>
                    <strong>(Hungary, Szombathely)</strong>
                  </span>
                            <span className="project-year">2021</span>
                            <p className="mt-3">
                                Implementation of a complete production line, involving Inox
                                cable tray planning, installation, cable laying, and power
                                panel wiring.
                            </p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img
                            src="images/class/BMW zaszlos.png"
                            className="d-block w-100"
                            alt="Zeppelin Automation project image"
                        />
                        <div className="project-info">
                            <h3 className="mb-1">BMW Factory</h3>
                            <span>
                    <strong>(Debrecen, Hungary)</strong>
                  </span>
                            <span className="project-year">2022</span>
                            <p className="mt-3">
                                The installation of lighting systems,
                                including the planning, and integration of
                                lighting fixtures to enhance safety, and energy efficiency.
                            </p>
                        </div>
                    </div>
                    <div className="project-card">
                        <img
                            src="images/class/kraft.png"
                            className="d-block w-100"
                            alt="Kraft Rental Kft project image"
                        />
                        <div className="project-info">
                            <h4 className="mb-1">
                                <b>Kraft Rental Kft.</b>
                            </h4>
                            <span>
                    <strong>(Hungary, Budapest)</strong>
                  </span>
                            <span className="project-year">2022</span>
                            <p className="mt-3">
                                Construction of a 1000 m2 brick building and 2 studios (1460
                                m2 and 1300 m2), ensuring high-current, low-current, and
                                lightning protection for Kraft Rental Kft.
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    </section>
)