import React, { useState } from "react";

export const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = 'Name is required';
        if (!formData.email) errors.email = 'Email is required';
        if (!formData.message) errors.message = 'Message is required';
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            // Manually create a form and submit it
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://api.web3forms.com/submit';
            form.style.display = 'none';

            const fields = {
                access_key: 'YOUR_ACCESS_KEY_HERE',
                name: formData.name,
                email: formData.email,
                message: formData.message,
            };

            for (const [key, value] of Object.entries(fields)) {
                const input = document.createElement('input');
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <section className="contact section" id="contact">
            <div className="container">
                <div className="row">
                    <div className="ml-auto col-lg-5 col-md-6 col-12">
                        <h2 className="mb-4 pb-2">Feel free to ask anything</h2>

                        <form
                            className="contact-form webform"
                            role="form"
                            onSubmit={handleSubmit}
                        >
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            {errors.name && <p style={{color: "red"}} className="error">{errors.name}</p>}

                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <p style={{color: "red"}} className="error">{errors.email}</p>}

                            <textarea
                                className="form-control"
                                rows="5"
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                            {errors.message && <p style={{color: "red"}} className="error">{errors.message}</p>}

                            <button
                                type="submit"
                                className="form-control"
                                id="submit-button"
                                name="submit"
                            >
                                Send Message
                            </button>
                        </form>
                    </div>

                    <div className="mx-auto mt-4 mt-lg-0 mt-md-0 col-lg-5 col-md-6 col-12">
                        <h2 className="mb-4">
                            Where you can <span>find us</span>
                        </h2>

                        <p>
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.62129 1.13607C9.81656 0.940808 10.1331 0.940809 10.3284 1.13607L11.3891 2.19673L12.8033 3.61094L13.8639 4.6716C14.0592 4.86687 14.0592 5.18345 13.8639 5.37871C13.6687 5.57397 13.3521 5.57397 13.1568 5.37871L12.5038 4.7257L8.86727 9.57443L9.97485 10.682C10.1701 10.8773 10.1701 11.1939 9.97485 11.3891C9.77959 11.5844 9.463 11.5844 9.26774 11.3891L7.85353 9.97491L6.79287 8.91425L3.5225 12.1846C3.32724 12.3799 3.01065 12.3799 2.81539 12.1846C2.62013 11.9894 2.62013 11.6728 2.81539 11.4775L6.08576 8.20714L5.0251 7.14648L3.61089 5.73226C3.41563 5.537 3.41562 5.22042 3.61089 5.02516C3.80615 4.8299 4.12273 4.8299 4.31799 5.02516L5.42557 6.13274L10.2743 2.49619L9.62129 1.84318C9.42603 1.64792 9.42603 1.33133 9.62129 1.13607Z"
                                    fill="currentColor"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                ></path>
                                <path
                                    d="M9.62129 1.13607C9.81656 0.940808 10.1331 0.940809 10.3284 1.13607L11.3891 2.19673L12.8033 3.61094L13.8639 4.6716C14.0592 4.86687 14.0592 5.18345 13.8639 5.37871C13.6687 5.57397 13.3521 5.57397 13.1568 5.37871L12.5038 4.7257L8.86727 9.57443L9.97485 10.682C10.1701 10.8773 10.1701 11.1939 9.97485 11.3891C9.77959 11.5844 9.463 11.5844 9.26774 11.3891L7.85353 9.97491L6.79287 8.91425L3.5225 12.1846C3.32724 12.3799 3.01065 12.3799 2.81539 12.1846C2.62013 11.9894 2.62013 11.6728 2.81539 11.4775L6.08576 8.20714L5.0251 7.14648L3.61089 5.73226C3.41563 5.537 3.41562 5.22042 3.61089 5.02516C3.80615 4.8299 4.12273 4.8299 4.31799 5.02516L5.42557 6.13274L10.2743 2.49619L9.62129 1.84318C9.42603 1.64792 9.42603 1.33133 9.62129 1.13607Z"
                                    fill="currentColor"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                            &nbsp; 1134, Budapest, Váci street 47. B. Ü-1 - Hungary
                        </p>

                        <div className="column this-row">
                            <div className="col-md-7 mb-3 second-card">
                                <div className="card" style={{width: "20rem"}}>
                                    <div className="card-body">
                                        <h3 className="card-title">Hortobágyi Dávid</h3>
                                        <h8 className="card-text">
                                            david.hortobagyi@buildingcontrol.hu
                                        </h8>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 mb-3 second-card">
                                <div className="card" style={{width: "20rem"}}>
                                    <div className="card-body">
                                        <h3 className="card-title">Torma Kristóf</h3>
                                        <h8 className="card-text">
                                            kristof.torma@buildingcontrol.hu
                                        </h8>
                                        <p className="card-text">+36 30 146 5091</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7 mb-3 second-card">
                                <div className="card" style={{width: "20rem"}}>
                                    <div className="card-body">
                                        <h3 className="card-title">Tihanyi Péter</h3>
                                        <h8 className="card-text">
                                            peter.tihanyi@buildingcontrol.hu
                                        </h8>
                                        <p className="card-text">+36 30 588 8962</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};