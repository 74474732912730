import React from "react";

export const Features = () => (
    <section className="about section" id="features">
        <div className="container">
            <div className="row ">
                <div className="mt-lg-5 mb-lg-0 mb-4 col-lg-5 col-md-10 mx-auto col-12 about-list-container">
                    <h1 className="mb-3 text-black this-is-title">
                        THIS IS
                        <br/> BUILDING CONTROL
                    </h1>

                    <ul>
                        <li className="about-list">
                            <b>Industrial automation</b> – design of automation for industrial production lines,
                            cabinet manufacturing. Electrical installation of production lines - project management,
                            wiring splicing, tray installation and connection of automation lines.
                        </li>
                        <li className="about-list">
                            <b>Complete building electricity</b> – site management, complete low and high voltage
                            construction.
                            Experienced in both, industrial and facility building electricity works.
                        </li>
                        <li className="about-list">
                            <b>PLC programming </b> – industrial PLC design, programming, testing. Installation and
                            maintenance of PLC components.
                        </li>
                        <li className="about-list">
                            <b>ISO certifications  </b> – authorization to carry out contact protection inspections and lightning protection inspections.
                        </li>
                    </ul>
                </div>

                <div
                    className="ml-lg-auto col-lg-3 col-md-6 col-12"
                >
                    <div className="team-thumb">
                    <img
                            src="images/team/team-image.jpg"
                            className="img-fluid"
                            alt="Trainer"
                        />

                        <div className="team-info d-flex flex-column">
                            <h3>Quality</h3>
                            <span>clear and professional work</span>
                        </div>
                    </div>
                </div>
                <div
                    className="mr-lg-auto mt-5 mt-lg-0 mt-md-0 col-lg-3 col-md-6 col-12"
                >
                    <div className="team-thumb">
                        <img
                            src="images/team/team-image01.jpg"
                            className="img-fluid"
                            alt="Trainer"
                        />

                        <div className="team-info d-flex flex-column">
                            <h3>Experience</h3>
                            <span>Qualified professionals</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)